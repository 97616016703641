/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Col, Container, Row } from "reactstrap";
import GoogleSignin from "./GoogleSignIn";
import logo from "../../../img/logo.png";

export default function Login() {

    return <Container>
        <Row className="align-items-center" css={css`height: 100vh`}>
            <Col className="text-center">
                <img style={{ margin: "1em" }} src={logo} alt="MADI" title="MADI" width="192em" height="192em" />
                <p className="lead">Welkom bij MADI!<br></br>Gebruik je Google account om in te loggen.</p>
                <div className="mx-auto text-center">
                    <GoogleSignin />
                </div>
            </Col>
        </Row>
    </Container>
}
