import { ToastContainer } from 'react-toastify';
import MainContent from "./Main"

//stylesheets
import 'react-toastify/dist/ReactToastify.css';
import { invokeServices } from '../services';

function App() {
    invokeServices();
    return (
        <div>
            <ToastContainer />
            <MainContent />
        </div>
    );
}

export default App;
