import {
    Routes,
    Route,
} from "react-router-dom";
import Inbox from "./scenes/Inbox";
import Signal from "./scenes/Signal";
import Login from "./scenes/Login";
import Powerbi from "./scenes/Powerbi";
import PageWithHeader from "./PageWithHeader";

function MainContent() {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<PageWithHeader page={Inbox} />} />
            <Route path="/signals/:signalId" element={<PageWithHeader page={Signal} />} />
            <Route path="/powerbi" element={<PageWithHeader page={Powerbi} />} />
        </Routes>
    )
}

export default MainContent;
